import React, { Component, lazy, useContext, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
// import { Route, Router, useLocationProperty,navigate  } from 'wouter';
import { Loader } from "rsuite";
import logo from "./logo.svg";
import "./App.css";
import "./styles/Nrjwtr.css";
import { NrjRequire } from "./utilities/NrjRequire";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NrjChatWdw from "./components/reusable/advncd/NrjChatWdw";
// import { NrjLgn } from './components/NrjLgn';

const LzNrjLnkPg = React.lazy(() => import("./components/admn/NrjLnkpg"));
const LzNrjLgn = React.lazy(() => import("./components/NrjLgn"));
const LzNrjWstCmp = React.lazy(() => import("./components/admn/NrjWstCmp"));
const LzNrjWstTdy = React.lazy(() => import("./components/admn/NrjWstTdy"));
const LzNrjWstHcf = React.lazy(() => import("./components/wstinc/NrjWstHcf"));
const LzNrjIncinrt = React.lazy(() => import("./components/wstinc/NrjIncinrt"));
const LzNrjPrgNts = React.lazy(() => import("./components/admn/NrjPrgNts"));
const LzNrjWstCld = React.lazy(() => import("./components/admn/NrjWstCltRpt"));
const LzNrjSlDts = React.lazy(() => import("./components/pharma/NrjSlDts"));
const LzNrjEqpSlctr = React.lazy(
  () => import("./components/wstinc/NrjEqpSlct")
);
const LzNrjWhlPur = React.lazy(() => import("./components/pharma/NrjWhlPur"));
const LzNrjIncinr = React.lazy(() => import("./components/wstinc/NrjIncinrt"));
const LzNrjPhrmPurSl = React.lazy(
  () => import("./components/pharma/NrjPhrmPurSl")
);
const LzNrjRadLst = React.lazy(() => import("./components/viewbox/NrjRadLst"));
const LzNrjPrgDts = React.lazy(() => import("./components/admn/NrjPrgdts"));
const LzNrjPrgLst = React.lazy(() => import("./components/admn/NrjPrgNLst"));
const LzNrjPurSbldacc = React.lazy(
  () => import("./components/pharma/NrjPurSbldacc")
);
const LzNrjWhslPur = React.lazy(
  () => import("./components/pharma/purchase/NrjWhslPur")
);
const LzNrjBrndSrch = React.lazy(
  () => import("./components/pharma/master/NrjBrndSrch")
);
const LzWstCndIncnrt = React.lazy(
  () => import("./components/wstinc/WstCndIncnrt")
);
const LzWstCndOTP = React.lazy(() => import("./components/wstinc/WstRegOTP"));
const LzWstInciBrcdVndr = React.lazy(
  () => import("./components/wstinc/CndInciBrcdVndr")
);
const LzCndInciSftVndr = React.lazy(
  () => import("./components/wstinc/CndInciSftVndr")
);
const LzCndincinSftr = React.lazy(
  () => import("./components/wstinc/CndincinSftFrt")
);
const LzRegThnks = React.lazy(() => import("./components/wstinc/RegThnks"));
const LzNrjLstReg = React.lazy(
  () => import("./components/wstinc/cpcb/NrjLstReg")
);
const LzNrjThnks = React.lazy(() => import("./components/wstinc/reg/NrjThnks"));
const LzTndrGblEml = React.lazy(
  () => import("./components/admn/tender/TndrGblEml")
);
const LzTndrPrjLst = React.lazy(
  () => import("./components/admn/tender/TndrPrjLst")
);
const LzTndrGrdLst = React.lazy(
  () => import("./components/admn/tender/TndrGrdLst")
);
const LzWrtCrtLgn = React.lazy(
  () => import("./components/admn/bckofc/WtrCrtLgn")
);
const LzNrjEmdBnk = React.lazy(() => import("./components/admn/tender/DepMny"));
const LzWtrpswds = React.lazy(
  () => import("./components/admn/bckofc/Wtr-pswds")
);
const LzNrjLnxStup = React.lazy(
  () => import("./components/admn/bckofc/NrjLnxStup")
);
const LzNrjLnxWht = React.lazy(
  () => import("./components/admn/bckofc/NrjLnxWht")
);
const LzNrjWstApi = React.lazy(()=> import ("./components/admn/prgrm/NrjWtrApi"))
const LzCmnNm = React.lazy(()=> import("./components/admn/bckofc/WstIncinrtr/CmpFltr"))
const LzPrjReq = React.lazy(()=> import("./components/admn/prgrm/PrjReqPlst"))
const LzStfTodo = React.lazy(()=> import("./components/admn/offstf/StafTodo"))
const LzWstRentr = React.lazy(()=> import("./components/wstinc/reuse/WstRentr"))
const LzCpcbVld = React.lazy(()=> import("./components/wstinc/CndInciVald"))
const LzWstIncrBlng = React.lazy(()=> import("./components/admn/bckofc/WstIncinrtr/WstIncrBlng"))
const Lzfncts = React.lazy(()=> import("./components/admn/fnctsDts"))
const LzAggregatorAdmin = React.lazy(()=>import("./app/brds/AggregatorAdmin"))
const LzAddMnMsg = React.lazy(()=>import("./app/brds/AddMnMsg"))
const LzBhwLic = React.lazy(()=> import("./app/lic/BhwLic"))
const App = () => {
  // const hashLocation = () => window.location.hash.replace(/^#/, "") || "/";
  // const hashNavigate = (to:any) => navigate('#' + to);
  // const useHashLocation = () => {
  //   const location = useLocationProperty(hashLocation);
  //   return [location, hashNavigate];
  // };
  // const [textDts, setTextDts] = useState("");

  // const onChangeDts = (data:string)=>{
  //   let dta : string = textDts;
  //   let fldN : any = utilities(2,data, "");
  //   if (textDts){
  //     dta = textDts + "=";
  //     let d : any =  utilities(1,dta,fldN);
  //     if (d){
  //       dta = d;
  //     }

  //   }
  //   dta += data;

  //   setTextDts(dta);

  // }

  useEffect(() => {
    // document.title = "Neerja Associates Pvt Ltd";
    document.title = "Neerja Associates";
    // sessionStorage.setItem("cmpid", "104506");
    // sessionStorage.setItem("usrid", "101");
  });
  // const queryClient = new QueryClient();
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/Wstfull"
            element={
              <React.Suspense fallback={<Loader content="Medium" />}>
                {/* <LzNrjLgn /> */}
                <LzNrjIncinr />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/LinkDts"
            element={
              <React.Suspense fallback={<Loader content="Medium" />}>
                <NrjRequire>
                  <LzNrjLnkPg />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/apierr"
            element={
              <React.Suspense fallback={<Loader content="Medium" />}>
                <NrjRequire>
                  <LzAddMnMsg />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/crtlgn"
            element={
              <React.Suspense fallback={<Loader content="Medium" />}>
                <NrjRequire>
                  <LzWrtCrtLgn />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bhwlic"
            element={
              <React.Suspense fallback={<Loader content="Medium" />}>
                <NrjRequire>
                  <LzBhwLic></LzBhwLic>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/icncr"
            element={
              <React.Suspense fallback={<Loader center size="lg" />}>
                <NrjRequire>
                  <LzNrjWstCmp />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          
          <Route
            path="/aggradmn"
            element={
              <React.Suspense fallback={<Loader content="Medium" />}>
                <NrjRequire>
                  <LzAggregatorAdmin></LzAggregatorAdmin>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/icntdy"
            element={
              <React.Suspense fallback={<Loader content="Medium" />}>
                <NrjRequire>
                  <LzNrjWstTdy />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/icncr"
            element={
              <React.Suspense fallback={<Loader center size="lg" />}>
                <NrjRequire>
                  <LzNrjWstCmp />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/indpcw"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjWstTdy />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjWstHcf />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/incnr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjIncinrt />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/prgnts"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjPrgNts />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/prgntslst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjPrgLst />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/prgdts"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjPrgDts />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/wstcltd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjWstCld />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          {/* <Route
              path="/vhcl"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjIncinrtr />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route> */}
          <Route
            path="/phrmsl"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjSlDts />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          {/* <Route
              path="/atclv"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjAutclv />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route> */}
          {/* <Route
              path="/shrdr"
              element={
                <React.Suspense
                  fallback={<Loader size="lg" content="Medium" />}
                >
                  <NrjRequire>
                    <LzNrjShrdr />
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route> */}
          <Route
            path="/eqp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjEqpSlctr />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/whpur"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjWhlPur />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/whpursl"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjPhrmPurSl />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/vbx"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjRadLst />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/phrmpursb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjPurSbldacc />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/phrmpurlst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjWhslPur />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/phrmprd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjBrndSrch />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          
          <Route
            path="/incblg"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzWstIncrBlng></LzWstIncrBlng>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                {/* <LzWstIncrBlng></LzWstIncrBlng> */}
                {/* <LzWstCndIncnrt /> */}
                {/* <Lzfncts></Lzfncts> */}
                <LzNrjLgn />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cndwstotp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzWstCndOTP />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cndbcrdvndr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzWstInciBrcdVndr />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/rntr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzWstRentr />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cndsftw"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCndInciSftVndr />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cndsftr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCndincinSftr />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/regthnks"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzRegThnks />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cpcblst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjLstReg />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/regthn"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjThnks />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/gbleml"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzTndrGblEml />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/prjtnd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzTndrPrjLst />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/prjlst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzTndrGrdLst />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/emd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjEmdBnk />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/psw"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzWtrpswds />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/lnxwht"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjLnxStup />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/lnxstp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjLnxWht />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
           <Route
            path="/apicr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNrjWstApi />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cmp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCmnNm />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/req"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                    <LzPrjReq />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/stfdo"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                    <LzStfTodo />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/vld"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                    <LzCpcbVld/>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
           
        </Routes>
        {/* </QueryClientProvider>  */}
      </BrowserRouter>
    </div>
  );
};
export default App;

